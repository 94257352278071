<template>
  <section class="faq-area gray-bg pt-115 pb-95">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8">
          <div class="section-title text-center mb-75">
            <h2>Perguntas frequentes</h2>
            <p>Tem dúvidas sobre nós ainda? Temos as respostas para suas perguntas</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="single-faq mb-50">
            <h4>Por que nos escolher?</h4>
            <p>Atualmente temos muito mais a oferecer do que nossos concorrentes. Garantimos que você não vai se arrepender de ter nos escolhido.</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="single-faq mb-50">
            <h4>Os valores não estão altos de mais?</h4>
            <p>Temos certeza que nossa loja vale o que é cobrado. E talvez você tenha essas dúvidas por não ter usado nossa loja ainda, fique tranquilo... tudo que fazemos é para garantir que você seja feliz enquanto usa nossa loja.</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="single-faq mb-50">
            <h4>O que preciso fazer para começar a usar a loja?</h4>
            <p>Clique no botão na seção acima, preencha suas informações e fique tranquilo! Você tem 1 mês grátis para se adaptar e ver que não pode viver sem nós!</p>
          </div>
        </div>
        <div class="col-md-6">
          <div class="single-faq mb-50">
            <h4>E se eu não gostar?</h4>
            <p>Não acreditamos que isso vá acontecer, porém, você pode solicitar reembolso em até 7 dias após a confirmação do pagamento da sua mensalidade!</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Faq',
  props: [

  ]
}
</script>

<style scoped>

</style>