var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"cover-hosting pt-115 pb-120"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"cover-tabs-wrap"},[_vm._m(1),_c('div',{staticClass:"tab-content mt-8",attrs:{"id":"myTabContent"}},[_c('div',{staticClass:"tab-pane fade show active",attrs:{"id":"c-hosting","role":"tabpanel","aria-labelledby":"c-hosting-tab"}},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-5 col-md-6"},[_c('div',{staticClass:"chosting-img mb-30"},[_c('owl-admin')],1)]),_vm._m(2),_vm._m(3)])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"s-hosting","role":"tabpanel","aria-labelledby":"s-hosting-tab"}},[_c('div',{staticClass:"row align-items-center"},[_vm._m(4),_vm._m(5),_c('div',{staticClass:"col-lg-5 col-md-6"},[_c('div',{staticClass:"chosting-img mb-30"},[_c('owl-shop')],1)])])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"v-hosting","role":"tabpanel","aria-labelledby":"v-hosting-tab"}},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col-lg-5 col-md-6"},[_c('div',{staticClass:"chosting-img mb-30"},[_c('owl-basket')],1)]),_vm._m(6),_vm._m(7)])]),_c('div',{staticClass:"tab-pane fade",attrs:{"id":"w-hosting","role":"tabpanel","aria-labelledby":"w-hosting-tab"}},[_c('div',{staticClass:"row align-items-center"},[_vm._m(8),_vm._m(9),_c('div',{staticClass:"col-lg-5 col-md-6"},[_c('div',{staticClass:"chosting-img mb-30"},[_c('owl-historic')],1)])])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-xl-6 col-lg-8"},[_c('div',{staticClass:"section-title text-center mb-75"},[_c('h2',[_vm._v("Conheça sua futura loja")]),_c('p',[_vm._v("Explore todos os recursos que oferecemos para você controlar sua loja.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-tabs",attrs:{"id":"myTab","role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link active",attrs:{"id":"c-hosting-tab","data-toggle":"tab","href":"#c-hosting","role":"tab","aria-controls":"c-hosting","aria-selected":"true"}},[_c('i',{staticClass:"far fa-fw fa-star"}),_vm._v(" Painel administrativo")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"s-hosting-tab","data-toggle":"tab","href":"#s-hosting","role":"tab","aria-controls":"s-hosting","aria-selected":"false"}},[_c('i',{staticClass:"far fa-fw fa-star"}),_vm._v(" Loja")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"v-hosting-tab","data-toggle":"tab","href":"#v-hosting","role":"tab","aria-controls":"v-hosting","aria-selected":"false"}},[_c('i',{staticClass:"far fa-fw fa-star"}),_vm._v(" Cesta de compras")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link",attrs:{"id":"w-hosting-tab","data-toggle":"tab","href":"#w-hosting","role":"tab","aria-controls":"w-hosting","aria-selected":"false"}},[_c('i',{staticClass:"far fa-fw fa-star"}),_vm._v(" Historico de compras")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"chosting-content mb-30"},[_c('h4',[_vm._v("Painel administrativo")]),_c('p',[_vm._v(" Todas as funções disponiveis no painel foram feitas para facilitar e agilizar ainda mais a sua criação/migração da loja. Estamos sempre melhorando cada vez mais as funcionalidades sempre com o mesmo proposito de deixar sua loja perfeita! ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"chosting-list mb-30"},[_c('ul',[_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Crie produtos.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Crie categorias.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Administre usuario.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Crie cupons de desconto.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Gerencie todas as compras feitas.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"chosting-content mb-30"},[_c('h4',[_vm._v("Loja")]),_c('p',[_vm._v(" Nossa loja é a mais fácil e prática possível. Tudo é facilmente visivel e utilizavel, sem deixar dúvidas ao cliente durante as compras! Além disso, é a mais bonita "),_c('i',{staticClass:"far fa-fw fa-smile"})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"chosting-list mb-30"},[_c('ul',[_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Listagem geral dos produtos.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Listagem por categoria.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Adicione descricoes aos produtos.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Adicione opcoes de escolha para cada produto.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Venda o que quiser.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"chosting-content mb-30"},[_c('h4',[_vm._v("Cesta de compras")]),_c('p',[_vm._v(" Esta parte crucial para finalizar sua venda foi separada etapa por etapa para não poluir a tela do seu cliente com um monte de informações e deixa-lo perdido. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"chosting-list mb-30"},[_c('ul',[_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Listagem dos produtos adicionados a cesta.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Presentear ou aplicar um cupom de desconto.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Autenticacao com o Discord.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Dados pessoais para controle no Painel.")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Varias Gateways de pagamento.")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-4 col-md-6"},[_c('div',{staticClass:"chosting-content mb-30"},[_c('h4',[_vm._v("Historico de compras")]),_c('p',[_vm._v(" Aqui será exibido todas as compras do cliente logado. Nessa página ele também poderá pagar pedidos que ainda não foram concluidos e também verificar o que ele comprou em cada compra ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-lg-3 col-md-6"},[_c('div',{staticClass:"chosting-list mb-30"},[_c('ul',[_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Visualizacao de compras por usuario")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Informacoes detalhadas de cada pedido")]),_c('li',[_c('i',{staticClass:"fas fa-check"}),_vm._v(" Pague com 1 clique")])])])])
}]

export { render, staticRenderFns }