<template>
  <section class="cover-hosting pt-115 pb-120">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8">
          <div class="section-title text-center mb-75">
            <h2>Conheça sua futura loja</h2>
            <p>Explore todos os recursos que oferecemos para você controlar sua loja.</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="cover-tabs-wrap">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="c-hosting-tab" data-toggle="tab" href="#c-hosting" role="tab" aria-controls="c-hosting"
                   aria-selected="true"><i class="far fa-fw fa-star"></i> Painel administrativo</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="s-hosting-tab" data-toggle="tab" href="#s-hosting" role="tab" aria-controls="s-hosting"
                   aria-selected="false"><i class="far fa-fw fa-star"></i> Loja</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="v-hosting-tab" data-toggle="tab" href="#v-hosting" role="tab" aria-controls="v-hosting"
                   aria-selected="false"><i class="far fa-fw fa-star"></i> Cesta de compras</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="w-hosting-tab" data-toggle="tab" href="#w-hosting" role="tab" aria-controls="w-hosting"
                   aria-selected="false"><i class="far fa-fw fa-star"></i> Historico de compras</a>
              </li>
            </ul>
            <div class="tab-content mt-8" id="myTabContent">
              <div class="tab-pane fade show active" id="c-hosting" role="tabpanel" aria-labelledby="c-hosting-tab">
                <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6">
                    <div class="chosting-img mb-30">
                      <owl-admin></owl-admin>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="chosting-content mb-30">
                      <h4>Painel administrativo</h4>
                      <p>
                        Todas as funções disponiveis no painel foram feitas para facilitar e agilizar ainda mais a sua criação/migração da loja.
                        Estamos sempre melhorando cada vez mais as funcionalidades sempre com o mesmo proposito de deixar sua loja perfeita!
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="chosting-list mb-30">
                      <ul>
                        <li><i class="fas fa-check"></i> Crie produtos.</li>
                        <li><i class="fas fa-check"></i> Crie categorias.</li>
                        <li><i class="fas fa-check"></i> Administre usuario.</li>
                        <li><i class="fas fa-check"></i> Crie cupons de desconto.</li>
                        <li><i class="fas fa-check"></i> Gerencie todas as compras feitas.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="s-hosting" role="tabpanel" aria-labelledby="s-hosting-tab">
                <div class="row align-items-center">
                  <div class="col-lg-3 col-md-6">
                    <div class="chosting-content mb-30">
                      <h4>Loja</h4>
                      <p>
                        Nossa loja é a mais fácil e prática possível. Tudo é facilmente visivel e utilizavel, sem deixar dúvidas ao cliente durante as compras!
                        Além disso, é a mais bonita <i class="far fa-fw fa-smile"></i>
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="chosting-list mb-30">
                      <ul>
                        <li><i class="fas fa-check"></i> Listagem geral dos produtos.</li>
                        <li><i class="fas fa-check"></i> Listagem por categoria.</li>
                        <li><i class="fas fa-check"></i> Adicione descricoes aos produtos.</li>
                        <li><i class="fas fa-check"></i> Adicione opcoes de escolha para cada produto.</li>
                        <li><i class="fas fa-check"></i> Venda o que quiser.</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-6">
                    <div class="chosting-img mb-30">
                      <owl-shop></owl-shop>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="v-hosting" role="tabpanel" aria-labelledby="v-hosting-tab">
                <div class="row align-items-center">
                  <div class="col-lg-5 col-md-6">
                    <div class="chosting-img mb-30">
                      <owl-basket></owl-basket>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="chosting-content mb-30">
                      <h4>Cesta de compras</h4>
                      <p>
                        Esta parte crucial para finalizar sua venda foi separada etapa por etapa para não poluir a tela do seu cliente com um monte de informações e deixa-lo perdido.
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-4 col-md-6">
                    <div class="chosting-list mb-30">
                      <ul>
                        <li><i class="fas fa-check"></i> Listagem dos produtos adicionados a cesta.</li>
                        <li><i class="fas fa-check"></i> Presentear ou aplicar um cupom de desconto.</li>
                        <li><i class="fas fa-check"></i> Autenticacao com o Discord.</li>
                        <li><i class="fas fa-check"></i> Dados pessoais para controle no Painel.</li>
                        <li><i class="fas fa-check"></i> Varias Gateways de pagamento.</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="w-hosting" role="tabpanel" aria-labelledby="w-hosting-tab">
                <div class="row align-items-center">
                  <div class="col-lg-4 col-md-6">
                    <div class="chosting-content mb-30">
                      <h4>Historico de compras</h4>
                      <p>
                        Aqui será exibido todas as compras do cliente logado. Nessa página ele também poderá pagar pedidos que ainda não foram concluidos e também verificar o que ele comprou em cada compra
                      </p>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6">
                    <div class="chosting-list mb-30">
                      <ul>
                        <li><i class="fas fa-check"></i> Visualizacao de compras por usuario</li>
                        <li><i class="fas fa-check"></i> Informacoes detalhadas de cada pedido</li>
                        <li><i class="fas fa-check"></i> Pague com 1 clique</li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-5 col-md-6">
                    <div class="chosting-img mb-30">
                      <owl-historic></owl-historic>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import owlAdmin from '@/components/showroom/owl/admin';
import owlShop from '@/components/showroom/owl/shop';
import owlBasket from '@/components/showroom/owl/basket';
import owlHistoric from '@/components/showroom/owl/historic';

export default {
  name: 'ShowRoom',
  components: {
    owlAdmin,
    owlShop,
    owlBasket,
    owlHistoric,
  },
  props: [

  ]
}
</script>

<style scoped>

</style>