<template>
  <primary-template>
    <section class="slider-area fix slider-bg" data-background="/assets/img/slider/slider_bg.png">
      <div class="container">
        <div class="row align-items-center justify-content-center align-content-center">
          <div class="col-lg-6 pt-155">
            <div class="slider-content">
              <h2>HCStoreRP</h2>
              <p>Tenha o controle de todos os seus ganhos com muita facilidade no painel. Monitore seus ganhos analisando intervalos de tempo mensais ou diários.</p>
              <div class="slider-btn">
                <a href="#plans" class="btn">Testar agora</a>
                <a href="#show-room" class="btn transparent-btn">Saiba mais</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <features-area id="features-area"></features-area>

    <show-room id="show-room"></show-room>

    <templates id="templates"></templates>

    <search-domain id="search-domain"></search-domain>

    <plans id="plans"></plans>

    <unlimited-features id="unlimited-features"></unlimited-features>

    <faq id="faq"></faq>
  </primary-template>
</template>

<script>
import primaryTemplate from '@/templates/primaryTemplate'
import featuresArea from '@/components/features'
import showRoom from '@/components/showroom'
import templates from '@/components/templates'
import searchDomain from '@/components/searchDomain'
import plans from '@/components/plans'
import unlimitedFeatures from '@/components/unlimted-features'
import faq from '@/components/faq'

export default {
  name: 'Home',
  components: {
    primaryTemplate,
    featuresArea,
    showRoom,
    templates,
    searchDomain,
    plans,
    unlimitedFeatures,
    faq,
  },
  data () {
    return {

    }
  },
  methods: {

  },
  created: function () {

  }
}
</script>

<style scoped>

.slider-area.fix.slider-bg {
  background: linear-gradient(150deg,rgb(66,6,76) 15%,rgba(91,9,107,.81) 70%,rgba(156,39,176,.95) 94%);
}

</style>