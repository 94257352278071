import { render, staticRenderFns } from "./watermark.vue?vue&type=template&id=49f6c589&scoped=true"
import script from "./watermark.vue?vue&type=script&lang=js"
export * from "./watermark.vue?vue&type=script&lang=js"
import style0 from "./watermark.vue?vue&type=style&index=0&id=49f6c589&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49f6c589",
  null
  
)

export default component.exports