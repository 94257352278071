<template>
  <div id="watermark">
    <a href="https://wa.me/551736009911" target="_blank" data-toggle="tooltip" data-placement="top" title="Desenvolvido por HubCode's">
      <img alt="HubCodes" width="30" src="../../assets/img/social/HubCodes_Preto.png" />
    </a>
  </div>
</template>

<script>
export default {
  name: "watermark"
}
</script>

<style scoped>

#watermark {
  position: fixed;
  z-index: 999;
  bottom: 10px;
  right: 10px;
}
#watermark img {
  border-radius: 15px;
  opacity: 0.5;
}
#watermark img:hover,
#watermark img:focus,
#watermark img:active {
  opacity: 1;
}

</style>
