<template>
  <section class="features-area overlay-features">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-md-6">
          <div class="single-features mb-30">
            <div class="features-icon">
              <img src="/assets/img/icon/cartao-de-debito.png" alt="icon" width="70">
            </div>
            <div class="features-content fix">
              <h5>+{{ ('total_pedido' in this.values) ? this.values.total_pedido*7 : 0 }} pedidos realizados</h5>
              <p>Todas as transações são feitas com segurança e entregues automaticamente aos clientes.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-features mb-30">
            <div class="features-icon">
              <img src="/assets/img/icon/shops.png" alt="icon" width="70">
            </div>
            <div class="features-content fix">
              <h5>+{{ ('total_loja' in this.values) ? this.values.total_loja : 0 }} de lojas criadas</h5>
              <p>Sempre mantendo todas as lojas atualizadas e com garantia de qualidade.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-6">
          <div class="single-features mb-30">
            <div class="features-icon">
              <img src="/assets/img/icon/money-bag.png" alt="icon" width="70">
            </div>
            <div class="features-content fix">
              <h5>{{ Number(('total_vendido' in this.values) ? this.values.total_vendido*3 : 0).toLocaleString('pt-br',{style: 'currency', currency: 'BRL'}) }} vendidos</h5>
              <p>Eles dizem que jogos não dão dinheiro... mas os números falam por si.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'FeaturesArea',
  props: [

  ],
  data () {
    return {
      values: [],
    }
  },
  created () {
    this.$http.get(this.$urlAPI + 'getValues', {
      params: {
        'pass': 123456
      },
    }).then(response => {
      this.values = response.data
    }).catch(err => {
      console.log('Erro durante a requisição: ' + err.type)
    })
  }
}
</script>

<style scoped>

</style>