<template>
  <section class="templates-area gray-bg pt-145 pb-140">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8">
          <div class="section-title search-title text-center mb-40">
            <h2>Três padrões incriveis</h2>
            <p>E você pode customizar da forma que achar melhor!</p>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-xl-10 col-lg-10">
          <div class="cover-tabs-wrap text-center">
            <ul class="nav nav-tabs" id="myTab2" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="c-template1-tab" data-toggle="tab" href="#c-template1" role="tab" aria-controls="c-template1"
                   aria-selected="true"><i class="far fa-fw fa-star"></i> Padrão 1</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="c-template2-tab" data-toggle="tab" href="#c-template2" role="tab" aria-controls="c-template2"
                   aria-selected="false"><i class="far fa-fw fa-star"></i> Padrão 2</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="c-template2-tab" data-toggle="tab" href="#c-template3" role="tab" aria-controls="c-template3"
                   aria-selected="false"><i class="far fa-fw fa-star"></i> Padrão 3</a>
              </li>
            </ul>
            <div class="tab-content mt-8" id="myTab2Content">
              <div class="tab-pane fade show active" id="c-template1" role="tabpanel" aria-labelledby="c-template1-tab">
                <img src="../../assets/img/templates/template-1.png" class="img-fluid rounded-xl" alt="" width="850">
                <a class="d-block w-100 text-dark mt-3 font-weight-bold" href="https://demo.hcrp.store" target="_blank"><i class="fa fa-fw fa-eye"></i> Veja uma demonstração</a>
              </div>
              <div class="tab-pane fade" id="c-template2" role="tabpanel" aria-labelledby="c-template2-tab">
                <img src="../../assets/img/templates/template-2.png" class="img-fluid rounded-xl" alt="" width="800">
                <a class="d-block w-100 text-dark mt-3 font-weight-bold" href="https://demo2.hcrp.store" target="_blank"><i class="fa fa-fw fa-eye"></i> Veja uma demonstração</a>
              </div>
              <div class="tab-pane fade" id="c-template3" role="tabpanel" aria-labelledby="c-template3-tab">
                <img src="../../assets/img/templates/template-3.png" class="img-fluid rounded-xl" alt="" width="800">
                <a class="d-block w-100 text-dark mt-3 font-weight-bold" href="https://demo3.hcrp.store" target="_blank"><i class="fa fa-fw fa-eye"></i> Veja uma demonstração</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";
import owlBasket from "@/components/showroom/owl/basket.vue";
import owlHistoric from "@/components/showroom/owl/historic.vue";
import owlShop from "@/components/showroom/owl/shop.vue";
import owlAdmin from "@/components/showroom/owl/admin.vue";

export default {
  name: 'Templates',
  components: {
    owlAdmin, owlShop, owlHistoric, owlBasket,
    Flicking
  },
  props: [

  ],
  data() {
    return {
      plugins: []
    }
  }
}
</script>

<style>

#templates .cover-tabs-wrap .nav-tabs .nav-link {
  background: #fff
}

#templates .cover-tabs-wrap .nav-link:hover {
  color: #fff;
  border-color: unset;
  background: #b622b5;
}

#templates  .cover-tabs-wrap .nav-link.active {
  color: #fff;
  border-color: unset;
  background: #b622b5;
}

</style>