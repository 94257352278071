<template>
  <section class="carousel-section">
    <Flicking class="rounded">
      <div class="card-panel">
        <img src="../../../assets/img/showroom/shop.png" alt="">
      </div>
      <div class="card-panel">
        <img src="../../../assets/img/showroom/shop_category.png" alt="">
      </div>
      <div class="card-panel">
        <img src="../../../assets/img/showroom/shop_confirm.png" alt="">
      </div>
    </Flicking>
  </section>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";

export default {
  name: 'OwlShop',
  components: {
    Flicking
  },
  data() {
    return {
      plugins: []
    }
  }
}
</script>

<style>

.carousel-section {
  //transform: scale(1) perspective(1040px) rotateY(20deg) rotateX(2deg) rotate(2deg);
}
.carousel-section img {
  width: 500px;
}

</style>