<template>
  <section class="carousel-section">
    <Flicking class="rounded" :plugins="plugins">
      <div class="card-panel">
        <img src="../../../assets/img/showroom/painel/inicio-adm.png" alt="">
      </div>
      <div class="card-panel">
        <img src="../../../assets/img/showroom/painel/configs-adm.png" alt="">
      </div>
      <div class="card-panel">
        <img src="../../../assets/img/showroom/painel/categorias-adm.png" alt="">
      </div>
      <div class="card-panel">
        <img src="../../../assets/img/showroom/painel/pacotes-adm.png" alt="">
      </div>
      <div class="card-panel">
        <img src="../../../assets/img/showroom/painel/descontos-adm.png" alt="">
      </div>
    </Flicking>
  </section>
</template>

<script>
import { Flicking } from "@egjs/vue-flicking";

export default {
  name: 'OwlAdmin',
  components: {
    Flicking,
  },
  data() {
    return {
      plugins: []
    }
  }
}
</script>

<style>

.carousel-section {
  //transform: scale(1) perspective(1040px) rotateY(20deg) rotateX(2deg) rotate(2deg);
}
.carousel-section img {
  width: 500px;
}

</style>