import Vue from 'vue'
import VueRouter from 'vue-router'
import moment from 'moment'

import home from '../views/home.vue'

moment.locale('pt');

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
