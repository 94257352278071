<template>
  <section class="hosting-plan-area hplan-bg p-relative pt-145 section-shaped">
    <div class="shape shape-style-1 shape-primary">
      <span class="span-150"></span>
      <span class="span-50"></span>
      <span class="span-50"></span>
      <span class="span-75"></span>
      <span class="span-100"></span>
      <span class="span-75"></span>
      <span class="span-50"></span>
      <span class="span-100"></span>
      <span class="span-50"></span>
      <span class="span-100"></span>
    </div>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8">
          <div class="section-title white-title text-center mb-75">
            <h2>Adquira já sua loja</h2>
            <p>Planos para você usar sem limites!</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4">
          <div class="single-hplan active text-center mb-30">
            <div class="hplan-head">
              <span>Gratuito</span>
              <h3>R$0 <small>/ mês</small></h3>
              <h6>Taxa de 7% em cada venda</h6>
            </div>
            <div class="hplan-body mb-40">
              <p>Para você que está começando seu servidor</p>
              <div class="hplan-icon w-100">
                <ul class="mb-10 mt-10 space-y-2 text-left">
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Edite as cores da sua loja</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Produtos ilimitados</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Categorias ilimitadas</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> 3 Sub-usuários</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Descontos ilimitados</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> MercadoPago</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Entregas instantâneas</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Sem limite de faturamento</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Integração com Discord</li>
                </ul>
              </div>
            </div>
            <div class="hplan-btn">
              <a href="https://cliente.hcrp.store/register/free" class="btn">Teste gratuitamente</a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4">
          <div class="single-hplan active text-center mb-30">
            <div class="hplan-head">
              <span>Básico</span>
              <h3>R$35 <small>/ mês</small></h3>
              <h6>Taxa de 4% em cada venda</h6>
            </div>
            <div class="hplan-body mb-40">
              <p>Ideal para servidores que estão em expansão</p>
              <div class="hplan-icon w-100">
                <ul class="mb-10 mt-10 space-y-2 text-left">
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Edite as cores da sua loja</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Produtos ilimitados</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Categorias ilimitadas</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Sub-usuários ilimitados</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Descontos ilimitados</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> MercadoPago, PayPal</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Entregas instantâneas</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Sem limite de faturamento</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Integração com Discord</li>
                </ul>
              </div>
            </div>
            <div class="hplan-btn">
              <a href="https://cliente.hcrp.store/register/basic" class="btn">Teste gratuitamente</a>
              <small class="d-block mt-2 font-weight-bold">O primeiro mês é por nossa conta!</small>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4">
          <div class="single-hplan active text-center mb-30">
            <div class="hplan-head">
              <span>Completo</span>
              <h3>R$60 <small>/ mês</small></h3>
              <h6>Taxa de 1% em cada venda</h6>
            </div>
            <div class="hplan-body mb-40">
              <p>Para ajudar seu servidor a crescer e vender ainda mais</p>
              <div class="hplan-icon w-100">
                <ul class="mb-10 mt-10 space-y-2 text-left">
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Edite as cores da sua loja</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Produtos ilimitados</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Categorias ilimitadas</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Sub-usuários ilimitados</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Descontos ilimitados</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Estátisticas</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> MercadoPago, PayPal, Pix e Boleto</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Entregas instantâneas</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Sem limite de faturamento</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Use seu próprio domínio</li>
                  <li class="text-sm text-gray-300"><i class="fa fa-fw fa-check"></i> Integração com Discord</li>
                </ul>
              </div>
            </div>
            <div class="hplan-btn">
              <a href="https://cliente.hcrp.store/register/full" class="btn">Teste gratuitamente</a>
              <small class="d-block mt-2 font-weight-bold">O primeiro mês é por nossa conta!</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Plans',
  props: [

  ]
}
</script>

<style scoped>

.hplan-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(150deg,rgb(66,6,76) 15%,rgba(91,9,107,.81) 70%,rgba(156,39,176,.95) 94%);
  width: 100%;
  height: 635px;
  z-index: 0;
}

.section-shaped {
  position: relative;
}
.section-shaped .shape {
  position: absolute;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}
.section-shaped .shape-style-1 :nth-child(1) {
  position: absolute;
  left: -4%;
  bottom: auto;
  background: rgba(255, 255, 255, .1);
  border-radius: 50%;
}
.section-shaped .shape-style-1 :nth-child(2) {
  position: absolute;
  right: 4%;
  top: 10%;
  background: rgba(255, 255, 255, .1);
  border-radius: 50%;
}
.section-shaped .shape-style-1 :nth-child(3) {
  position: absolute;
  top: 280px;
  right: 5.66666%;
  background: rgba(255, 255, 255, .3);
  border-radius: 50%;
}
.section-shaped .shape-style-1 :nth-child(4) {
  position: absolute;
  top: 320px;
  right: 7%;
  background: rgba(255, 255, 255, .15);
  border-radius: 50%;
}
.section-shaped .shape-style-1 :nth-child(5) {
  position: absolute;
  top: 19%;
  left: 1%;
  right: auto;
  background: rgba(255, 255, 255, .05);
  border-radius: 50%;
}
.section-shaped .shape-style-1 :nth-child(6) {
  position: absolute;
  width: 200px !important;
  height: 200px !important;
  top: 32%;
  left: 10%;
  right: auto;
  background: rgba(255, 255, 255, .15);
  border-radius: 50%;
}
.section-shaped .shape-style-1 :nth-child(7) {
  position: absolute;
  bottom: 50%;
  right: 36%;
  background: rgba(255, 255, 255, .04);
  border-radius: 50%;
}
.section-shaped .shape-style-1 :nth-child(8) {
  position: absolute;
  top: 50%;
  right: 2%;
  background: rgba(255, 255, 255, .2);
  border-radius: 50%;
}
.section-shaped .shape-style-1 :nth-child(9) {
  position: absolute;
  bottom: 1%;
  right: 2%;
  background: rgba(255, 255, 255, .1);
  border-radius: 50%;
}
.section-shaped .shape-style-1 :nth-child(10) {
  position: absolute;
  top: 52%;
  left: 1%;
  right: auto;
  background: rgba(255, 255, 255, .05);
  border-radius: 50%;
}

.section-shaped .shape-style-1 .span-50 {
  display: block;
  height: 50px;
  width: 50px;
}
.section-shaped .shape-style-1 .span-75 {
  display: block;
  height: 75px;
  width: 75px;
}
.section-shaped .shape-style-1 .span-100 {
  display: block;
  height: 100px;
  width: 100px;
}
.section-shaped .shape-style-1 .span-150 {
  display: block;
  height: 150px;
  width: 150px;
}

</style>